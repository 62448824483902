/******************************************
/* LAYOUT
/*******************************************/

/* Center the container */
#container {
	width: 960px;
	margin: auto;
 text-align: center;
}
header, footer {
  padding: 20px;
}

img {
  width: 100px;
}

/******************************************
/* ANIMATION STYLES
/*******************************************/
@-webkit-keyframes spin-me{
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes spin-me {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

.thing-to-spin {
  /*animation-name*/
  -webkit-animation-name: spin-me;
  animation-name: spin-me;
  /*animation-iteration-count*/
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  /*animation-duration*/
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  /*animation-timing-function*/
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;

  /* Shorthand */
/*  -webkit-animation: spin-me 500ms linear infinite;
  animation: spin-me 500ms linear infinite;*/
}
