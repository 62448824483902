html, body {
	width: 100%;
	padding: 0;
	margin: 0;
	background: #FAFAFA;
	font-family: 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}


#app {
	min-height: 100vh;
	height: 100%;
}


#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 9rem;    /* Footer height */
	padding-left: 15px;
	padding-right: 15px;
}

footer {
  position: absolute;
  background-color: #FAFAFA;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
	font-size: 14px;
  text-align: center;
	height: 9rem;
}
